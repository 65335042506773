<template>
  <FormCard
    :title="agencyname ? agencyname : 'Nova Agência'"
    :status="agencystatus"
    @save="validate"
    @cancel="$router.push({ name: 'agencies' })"
  >
    <v-form
      class="px-5"
      ref="form"
    >
      <input
        type="password"
        name="password"
        label="senha"
        class="d-none"
        autocomplete="new-password"
      />
      <section
        v-for="(section, idx) in sections"
        :key="idx"
      >
        <SectionTitle :title="section.title" />
        <component
          :is="section.component"
          :agency="agency"
        />
      </section>
    </v-form>
  </FormCard>
</template>

<script>
export default {
  components: {
    SectionTitle: () => import('@/components/SectionTitle'),
    FormCard: () => import('@/components/FormCard'),
    BasicData: () => import('./form-sections/BasicData'),
    Documents: () => import('./form-sections/Documents'),
    Location: () => import('./form-sections/Location'),
  },
  props: {
    agency: {
      type: Object,
      required: true,
    },
    type: String,
  },
  data: () => ({
    sections: [
      { title: 'Dados Básicos', component: 'basic-data' },
      { title: 'Documentos', component: 'documents' },
      { title: 'Endereço', component: 'location' },
    ],
    profile: null,
  }),
  methods: {
    validate() {
      let isValid = this.$refs.form.validate()
      isValid ? this.$emit('save') : ''
    },
    getUserProfile() {
      this.$api
        .get(`people/${this.$cookies.get('isAuth')}`, {
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.user = res.data.data
          this.$store.commit('setProfile', this.user.profile)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    if (this.$cookies.get('type') == 'user' && this.type == 'edit') {
      if (this.$store.state.profile.length == 0) {
        this.getUserProfile()
      }
    }
  },
}
</script>
